import { FC } from 'react';
import Link from 'next/link';
import styles from './Footer.module.scss';

const Footer: FC = () => {
  const FOOTER_LINKS = [
    { link: '/redirect_to_litres', name: 'Litres.ru' },
    { link: '/redirect_to_self_pub', name: 'Стать автором' },
    { link: '/redirect_to_my_book', name: 'MyBook.ru' },
  ];

  return (
    <ul className={styles.footer}>
      {FOOTER_LINKS.map((item, i) => (
        <li className={styles.footer__item}>
          <Link prefetch={false} key={`${item.name}-${i}`} className={styles.footer__link} href={item.link}>
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Footer;

import { FC } from 'react';
import Link from 'next/link';
import styles from './SideBanner.module.scss';

const SideBanner: FC = () => {
  return (
    <div className={styles.sideBanner}>
      <Link prefetch={false} className={styles.sideBanner__link} target="_blank" href="/redirect_to_self_pub">
        <img width="200" height="300" className={styles.sideBanner__img} src="/banners/selfpub-200x300.jpg" alt="side-banner" />
        <img width="320" height="240" className={`${styles.sideBanner__img} ${styles.sideBanner__img_mobile}`} src="/banners/selfpub-320x240.jpg" alt="side-banner" />
      </Link>
    </div>
  );
};

export default SideBanner;
